import './TabScroll.scss';

function TabScroll() {
  return (
    <>
      <div className='card'>
        <h2><span>About Me</span>My Name is Taylor</h2>
        <p>
          More specifically Taylor Runge, I am in my 4th year Studying Software Engineering at the University of Nebraska Lincoln.
        </p>
      </div>
      <div className='card'>
        <h2><span>Project #2</span>Title of the Project</h2>
      </div>
      <div className='card'>
        <h2><span>Project #3</span>Title of the Project</h2>
      </div>
    </>
  );
}

export default TabScroll;