import './App.css';

import Helmet from 'react-helmet';

import TabScroll from './Elements/TabScroll';

function App() {
  return (
    <>
      <header>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Taylor Runge</title>
        </Helmet>
      </header>
      <main className='wrapper'>
        <TabScroll/>
      </main>
    </>
  );
}

export default App;
